.navbar {
    color: #fff;
}

.header {
    font-size: 1.2em; 
    padding-left: 0px;
}

.image {
    margin-right: 1.5em;
}