.wrapper {
    position: relative;    
    bottom: 0;
    min-height: calc(100vh - 60px);
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    min-height: 60px;
    left:0;
    right:0;
}


.content {
    white-space: pre-wrap;
    font-size: 12;
}

.content .content-right {
    text-align: right;
}